import React from 'react';
import { useSelector } from 'react-redux';
import { useSettings } from '@wix/tpa-settings/react';
import {
  useTranslation,
  useEnvironment,
  useExperiments,
  useErrorMonitor,
} from '@wix/yoshi-flow-editor';

import { Text } from 'wix-ui-tpa';

import {
  selectGroup,
  selectHasAdminRole,
  selectMemberLabel,
} from 'store/groups/selectors';
import { AdminBadge } from 'common/components/AdminBadge';
import { getKey } from 'common/components/PluralText';

import settingsParams from 'Group/settingsParams';

import { DETAILS, DETAILS_TITLE } from './dataHooks';
import { classes, st } from './GroupMeta.st.css';

interface IGroupMetaProps {
  groupId: string;
}

export function GroupMeta(props: IGroupMetaProps) {
  const { t } = useTranslation();
  const settings = useSettings();
  const environment = useEnvironment();
  const errorMonitor = useErrorMonitor();
  const { experiments } = useExperiments();

  const group = useSelector(selectGroup(props.groupId));
  const isAdmin = useSelector(selectHasAdminRole(props.groupId));
  const memberLabel = useSelector(selectMemberLabel(props.groupId));

  const showMembersCount = settings.get(settingsParams.showMembersCount);

  const privacyStatusTextKey = React.useMemo(() => {
    const privacyStatus = group.privacyStatus;
    if (!privacyStatus) {
      return null;
    }

    switch (privacyStatus.toLowerCase()) {
      case 'public':
        return 'groups-web.types.public.name';
      case 'private':
        return 'groups-web.types.private.name';
      case 'secret':
        return 'groups-web.types.secret.name';
      default:
        errorMonitor.captureMessage(
          `Unknown privacy status: ${privacyStatus}`,
          {
            contexts: {
              groupDetails: {
                group,
              },
            },
          },
        );
        return null;
    }
  }, [group.privacyStatus]);

  return (
    <div
      data-hook={DETAILS}
      className={st(classes.root, { mobile: environment.isMobile })}
    >
      <div className={classes.titleAndRole}>
        <Text tagName="h1" data-hook={DETAILS_TITLE} className={classes.title}>
          {group.name}
        </Text>
        {!environment.isMobile && isAdmin && (
          <>
            <div className={classes.titleAndRoleDelimiter} />
            <AdminBadge />
          </>
        )}
      </div>
      <div className={classes.privacyAndMembers}>
        {environment.isMobile && isAdmin && (
          <>
            <AdminBadge />
            <div className={classes.titleAndRoleDelimiter} />
          </>
        )}

        {privacyStatusTextKey && (
          <Text className={classes.groupInfoItem}>
            {t(privacyStatusTextKey)}
          </Text>
        )}

        {showMembersCount && (
          <>
            <Text className={classes.groupInfoItem}>&nbsp;·&nbsp;</Text>

            <Text
              data-hook="members-count"
              className={st(classes.groupInfoItem)}
            >
              {!memberLabel.isCustom
                ? t(
                    getKey(
                      experiments,
                      `${memberLabel.label}.count`,
                      group.membersCount as number,
                    ),
                    {
                      count: group.membersCount as number,
                      formattedCount: group.membersCount as number,
                    },
                  )
                : `${group.membersCount} ${memberLabel.label}`}
            </Text>
          </>
        )}
      </div>
    </div>
  );
}
